<template>
  <div
    ref="userPanel"
    class="border-skin-border relative cursor-pointer border-b lg:mb-4 lg:p-4 lg:pl-6"
    @click="togglePanel"
  >
    <div class="flex items-center justify-start gap-2">
      <div class="flex-shrink overflow-hidden">
        <div class="flex items-center gap-2">
          <div
            v-if="expanded"
            class="text-skin-heading hidden max-w-full flex-shrink overflow-hidden text-ellipsis text-sm font-medium lg:block"
            :title="workspaceName"
          >
            {{ workspaceName }}
          </div>
          <UserInitials v-else class="hidden lg:block" />
          <div class="flex flex-shrink-0 items-center gap-2">
            <UserInitials class="lg:hidden" />
            <ChevronDownIcon class="text-skin-heading h-4 w-4" />
          </div>
        </div>
        <div v-if="expanded" class="text-skin-muted hidden text-xs lg:block">
          {{ theUser.firstname }} {{ theUser.lastname }}
        </div>
      </div>
    </div>
    <div
      v-if="panelOpen"
      class="bg-skin-fill-2 z-dropdown shadow-dropdown absolute left-4 top-[calc(100%-0.5rem)] rounded-md py-4"
    >
      <div class="divide-skin-border flex flex-col gap-2 divide-y">
        <div class="flex items-center gap-2 px-6">
          <UserInitials />
          <div class="text-sm">
            <div>{{ theUser.firstname }}</div>
            <div class="text-skin-muted">{{ theUser.email }}</div>
          </div>
        </div>
        <div class="flex flex-col gap-2 pt-2">
          <SideBarUserPanelAction label="Changer de marque" :children="workspacesSwitchChildren" />
          <SideBarUserPanelAction
            label="Se déconnecter"
            :action="logout"
            :icon="ArrowLeftOnRectangleIcon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowLeftOnRectangleIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import { storeToRefs } from 'pinia';
import { useUserWorkspaces } from '~/composables/users/useUserWorkspaces';
import type { User } from '~/types/models/user';
import { useAuthStore } from '~/stores/auth';

type Props = {
  expanded: boolean;
};

const props = defineProps<Props>();

const { workspace, user } = storeToRefs(useAuthStore());

const theUser = computed((): any => user.value || { firstname: '', lastname: '', email: '' });

const workspaceName = computed(() => (workspace.value as any)?.name || '');

const panelOpen = ref(false);

const togglePanel = () => {
  panelOpen.value = !panelOpen.value;
};

const userPanel = ref(null);

onClickOutside(userPanel, () => {
  panelOpen.value = false;
});

const logout = () => {
  panelOpen.value = false;
  useAuthStore().logout();
};

const switchWs = (id: number) => {
  return useAuthStore().switchWorkspace(id);
};

const workspacesSwitchChildren = computed(() => {
  const user = useAuthStore().user;

  return useUserWorkspaces(user as User).map((w: any) => {
    const urlLogo =
      w.informations_marque.logo?.formats?.thumbnail?.url || w.informations_marque.logo?.url;
    return {
      label: w.name,
      action: () => switchWs(w.id),
      icon: urlLogo,
    };
  });
});
</script>

<style scoped></style>
