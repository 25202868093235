<template>
  <NuxtLink to="/" class="flex flex-shrink-0 items-center rounded-md" v-if="logoUrl">
    <img :src="logoUrl" class="h-full w-auto" />
  </NuxtLink>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useInformationsMarqueStore } from '~/stores/informationsMarque';

const infosStore = useInformationsMarqueStore();
const { infos } = storeToRefs(infosStore);

const logo = computed(() => infos.value?.logo || null);
const logoUrl = computed(() =>
  logo.value?.formats ? logo.value?.formats['thumbnail']?.url : logo.value?.url || '',
);
</script>
