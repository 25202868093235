<template>
  <div
    ref="thisComponent"
    class="hover:bg-skin-fill relative flex min-h-[2rem] shrink-0 cursor-pointer items-center justify-between gap-4 px-6 py-2 text-sm"
    @click="doAction"
  >
    <span class="whitespace-nowrap">{{ label }}</span>
    <template v-if="icon">
      <component v-if="!iconIsString" :is="icon" class="text-skin-muted w-6" />
      <div
        v-else
        class="border-skin-border flex h-8 w-8 flex-shrink-0 items-center rounded-md border p-1"
      >
        <img :src="(icon as string)" class="" />
      </div>
    </template>
    <ChevronRightIcon v-if="children" class="text-skin-muted w-6" />
    <div
      v-if="children && children.length && isHovered"
      class="shadow-dropdown z-dropdown absolute top-0 left-[calc(100%+0.2rem)] w-fit rounded-md bg-white py-2"
    >
      <SideBarUserPanelAction
        v-for="child in children"
        :key="child.label"
        :label="child.label"
        :action="child.action"
        :icon="child.icon"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChevronRightIcon } from '@heroicons/vue/24/outline';

type Props = {
  action?: Function;
  label: string;
  children?: Props[];
  icon?: any;
};

const props = defineProps<Props>();

const iconIsString = computed(() => typeof props.icon === 'string');

const estFonctionAsynchrone = (fonction: any) => {
  return fonction.constructor.name === 'AsyncFunction';
};

const doAction = async (e: Event) => {
  if (props.action) {
    if (estFonctionAsynchrone(props.action)) await props.action();
    else props.action();
  } else {
    e.stopPropagation();
  }
};

const thisComponent = ref(null);
const isHovered = useElementHover(thisComponent);
</script>
