<template>
  <div v-if="item" class="side-menu-item">
    <NuxtLink
      :to="useNavItemTo(item)"
      class="side-menu-item__link"
      :class="{
        'gap-0': !expanded,
        'lg:after:!-left-4': !expanded,
        'justify-center': !expanded,
        root: item.isRoot,
        'router-link-active': isActive,
      }"
      @click="useAppStore().closeMobileVerticalMenu()">
      <component class="w-5" :is="item.icon"></component>
      <span v-if="expanded"> {{ item.label }}</span>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { useNavItemTo } from '~/composables/navigation/useNavItemTo';
import { useNavMenuStore } from '~/stores/navigation/menus';
import type { NavItem } from '~~/types/NavItem';

type Props = {
  expanded: boolean;
  item: NavItem;
};

const props = withDefaults(defineProps<Props>(), {
  expanded: false,
});

const isActive = computed(() => {
  return useNavMenuStore().currentLevel1NavItem?.name === props.item?.name;
});
</script>

<style scoped>
.side-menu-item__link {
  @apply relative z-base flex min-h-8 items-center gap-3 px-4 py-2;
  @apply text-sm font-normal text-skin-heading text-opacity-70;
  @aplly transition-all duration-200;
  @apply rounded-xl bg-skin-accent bg-opacity-0 hover:bg-opacity-5;
  @apply after:absolute after:-left-4 after:top-1/2 after:z-hide after:h-6 after:w-1 after:-translate-y-1/2 after:rounded-r-full after:bg-skin-accent after:content-[*] lg:after:-left-6;

  &.router-link-exact-active,
  &.router-link-active:not(.root) {
    @apply text-opacity-100;
    @apply bg-opacity-10;
    @apply after:content-[''];
  }

  /* &.router-link-active:not(.root) {
    @apply text-opacity-100;
    @apply after:content-[''];
  }
  &.router-link-active:not(.root) ~ .children {
    @apply block;
  } */
}
</style>
