<template>
  <div
    class="bg-skin-fill-5 text-skin-inverted text-md flex items-center justify-center rounded-full p-2 font-medium uppercase">
    {{ useInitials }}
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import type { User } from '~/types/models/user';

const { user }: { user: Ref<User> } = storeToRefs(useAuthStore());

const useInitials = computed(() => user?.value?.firstname[0] + user?.value?.lastname[0] || '');
</script>

<style scoped></style>
