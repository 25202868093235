<template>
  <div class="mx-auto">
    <Button ref="feedbackBtn" :icon="MegaphoneIcon" variant="secondary">{{
      showLabel ? 'Signaler un bug' : ''
    }}</Button>
  </div>
</template>

<script setup lang="ts">
import { MegaphoneIcon } from '@heroicons/vue/24/outline';
import Button from '../button/button.vue';
import * as Sentry from '@sentry/nuxt';

type Props = {
  showLabel: boolean;
};

const props = defineProps<Props>();

const feedbackBtn = useTemplateRef('feedbackBtn');

watch(feedbackBtn, (newBtn) => {
  if (feedbackBtn.value) {
    const feedback = Sentry.getFeedback();

    const me = useAuthStore().user;

    Sentry.setUser({
      username: `${me?.firstname} ${me?.lastname}`,
      email: me.email,
    });
    feedback?.attachTo(feedbackBtn.value.$el);
  }
});
</script>

<style scoped></style>
