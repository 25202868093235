<template>
  <div
    class="relative flex h-full gap-4 lg:flex-col lg:gap-0"
    :class="{ 'items-center': !sideBarExpanded }">
    <SideBarToggle
      :state="sideBarExpanded"
      class="absolute left-full top-1/2 hidden -translate-y-1/2 lg:flex"
      @click="appStore.toggleSideBar()" />
    <div
      class="flex items-center justify-center pl-4 lg:hidden lg:items-start"
      @click="appStore.toggleMobileVerticalMenu()">
      <Bars3Icon class="h-8 w-8 text-skin-heading" />
    </div>

    <div
      class="relative order-2 box-content flex flex-shrink-0 grow items-center justify-end border-skin-border border-opacity-100 px-4 lg:-order-none lg:h-[80px] lg:grow-0 lg:justify-center lg:border-b-2 lg:px-6"
      :class="{ 'justify-center lg:w-full lg:!px-0 ': !sideBarExpanded }">
      <BrandLogo
        :expanded="sideBarExpanded"
        class="h-full max-w-full py-4"
        :class="{ '!h-auto !w-16 ': !sideBarExpanded }" />
    </div>
    <div class="flex h-full items-center lg:flex-col lg:items-start">
      <SideBarUserPanel :expanded="sideBarExpanded" class="order-2 lg:order-1" />
      <NavVertical class="flex-grow lg:order-2" />
      <div class="order-3 ml-auto flex w-full justify-center p-4">
        <FeedbackButton
          v-if="feedBackButtonVisible"
          :show-label="sideBarExpanded && !isSmallScreen" />
      </div>
    </div>
    <div
      class="relative box-content hidden flex-shrink-0 items-center border-skin-border border-opacity-100 lg:flex lg:h-[60px] lg:border-y-2"
      :class="{ 'justify-center': !sideBarExpanded }">
      <div class="flex items-center gap-2">
        <AppLogo
          :logo-size="sideBarExpanded ? '80' : '36'"
          class="pl-4 pr-4 lg:pl-6"
          :class="{ '!pl-4 lg:!pl-4': !sideBarExpanded }" />
        <div class="flex flex-col items-end text-xs text-skin-muted" v-if="sideBarExpanded">
          <div class="flex items-center gap-1">
            <SocketStatus />
            <AppVersion />
          </div>
          <AppYear />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Bars3Icon } from '@heroicons/vue/24/outline';
import { storeToRefs } from 'pinia';
import { useAppStore } from '~/stores/app';
import FeedbackButton from './sentry/feedbackButton.vue';

const appStore = useAppStore();
const { sideBarExpanded } = storeToRefs(appStore);

const { width: windowWidth } = useWindowSize();

const isSmallScreen = computed(() => windowWidth.value < 1024);

const feedBackButtonVisible = computed(() => useRuntimeConfig().public.SENTRY_DSN !== '');
</script>
