<template>
  <VersionChecker>
    <NetworkMonitor>
      <main class="flex h-screen w-screen flex-col">
        <div
          class="layout-container h-full w-full"
          :class="{ 'side-bar-hidden': !sideBarExpanded }"
        >
          <div
            class="layout-sidebar z-sticky border-skin-border bg-skin-side-bar relative border-b-2 md:z-[1] lg:border-b-0 lg:border-r-2"
          >
            <side-bar />
          </div>
          <div class="layout-content bg-skin-workspace relative" ref="layoutContent">
            <div class="flex h-full w-full flex-col overflow-x-auto" ref="layoutContentScroller">
              <div class="flex-grow p-4">
                <slot />
              </div>
              <div class="content-footer bg-skin-fill-2 text-skin-muted mt-4 px-4 py-2 text-sm">
                <div class="flex items-center justify-between lg:hidden">
                  <AppLogo :logo-size="'46'" />
                  <div class="flex items-center justify-end gap-2">
                    <span>Briik</span>
                    <AppYear />
                    <AppVersion />
                    <SocketStatus />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </NetworkMonitor>
  </VersionChecker>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAppStore } from '~/stores/app';

const appStore = useAppStore();

const { sideBarExpanded } = storeToRefs(appStore);

const layoutContent = ref(null);
const layoutContentScroller = ref(null);

watch(
  () => layoutContentScroller.value,
  () => {
    if (layoutContentScroller.value) {
      appStore.setScroller(layoutContentScroller.value);
    }
  },
);
</script>

<style lang="scss" scoped>
// bg-gradient-to-b from-skin-fill to-skin-fill-4

.layout-container {
  display: grid;
  grid-template:
    'b b'
    'c c';

  grid-template-rows: $headerHeight 1fr;

  @screen lg {
    grid-template:
      'b c'
      'b c';

    grid-template-columns: $sideBarWidth 1fr;
  }

  &.side-bar-hidden {
    grid-template-columns: $sideBarCollapsedWidth 1fr;
  }

  .layout-sidebar {
    grid-area: b;
  }
  .layout-content {
    grid-area: c;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
