<template>
  <div
    class="bg-skin-fill-2 z-sticky absolute left-0 top-[calc(4rem+2px)] h-[calc(100vh-calc(4rem+2px))] w-full grow -translate-x-[calc(100%+4rem)] transition-all will-change-transform lg:relative lg:top-0 lg:z-auto lg:h-auto lg:translate-x-0 lg:bg-transparent"
    :class="{ '!translate-x-0': mobileVerticalMenuVisible }"
  >
    <div class="relative h-full w-full overflow-y-auto overflow-x-hidden py-4 pr-4 lg:py-0">
      <div
        class="flex flex-col gap-[1px] pl-4 lg:pl-6"
        :class="{ '!pl-4 lg:!pl-4': !sideBarExpanded }"
      >
        <template v-for="item in allowedItems" :key="item.name">
          <NavVerticalItem :item="item" :expanded="sideBarExpanded" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAppStore } from '~/stores/app';
import { useNavMenuStore } from '~~/stores/navigation/menus';

const appStore = useAppStore();
const { sideBarExpanded, mobileVerticalMenuVisible } = storeToRefs(appStore);

const { allowedItems } = useNavMenuStore();
</script>
